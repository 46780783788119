/* NavBar.module.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

@font-face {
    font-family: "AbsoluteBeautyScriptRegular";
    src: local("AbsoluteBeautyScriptRegular"),
        url("../fonts/AbsoluteBeautyScriptRegular.otf") format("opentype");
    font-weight: normal;
}

@font-face {
    font-family: "commuterssans";
    src: local("commuterssans"),
        url("../fonts/commuterssans.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "commuterssans-light";
    src: local("commuterssans-light"),
        url("../fonts/CommutersSans-Light.ttf") format("truetype");
    font-weight: normal;
}

.navbar {
    /* font-family: 'Roboto', sans-serif; */
    background-color: #1877F2;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    text-align: center;
    font-family: commuterssans-light;
}

/* .navbar-brand {
    font-family: AbsoluteBeautyScriptRegular;
    font-weight: 400;
    font-style: normal;
    font-size: 55px;
    letter-spacing: 0em;
} */


.navLink {
    color: #c9c1c1;
    font-weight: 1500;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    transition: color 0.3s, background-color 0.3s;
    text-decoration: none;
    border-radius: 5px;
    margin-left: 30px;
    margin-right: 30px;
}

.navLink:hover {
    background-color: beige;
}