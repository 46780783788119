#RSVPcontainer {
  /* position: relative; */
  width: 100%;
  padding-top: 66px;
  height: unset;
}

#RSVPcontainer .btn {
  background-color: rgba(245, 245, 255, 0);
  border-color: #342F7E;
  height: 75px;
  width: 200px;
  font-size: 40px;
  border-width: 5%;
  border-radius: 0%;
  color: #342F7E;
  margin-top: 60px;
}

#RSVPcontainer .btn:hover {
  background-color: rgba(245, 245, 255, 0.3);
  color: #ffffff;
  border-color: #342F7E;
  height: 75px;
  width: 200px;
  font-size: 40px;
}

#RSVPcontainer .container {
  display: contents;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
}

.RSVP-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  gap: 5px;
  width: 80%;
  background: antiquewhite;
  background-color: rgba(255, 255, 255, 0.3);
}


.form-group {
  margin-bottom: 10px;
  text-align: center;
}

.guest-details {
  /* display: flex;
  align-items: baseline; */
  gap: 10px;
}

.form-control {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .RSVP-form {
    width: 100%;
    border: none;
    background-color: transparent;
  }

  .form-label {
    display: none;
  }
}