@import url('https: //fonts.google.com/specimen/Great+Vibes?query=Great+Vibes');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');

@font-face {
  font-family: "AbsoluteBeautyScriptRegular";
  src: local("AbsoluteBeautyScriptRegular"),
    url("../fonts/AbsoluteBeautyScriptRegular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "catchyMager";
  src: local("catchyMager"),
    url("../fonts/CatchyMager-Normal.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "commuterssans";
  src: local("commuterssans"),
    url("../fonts/commuterssans.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "commuterssans-light";
  src: local("commuterssans-light"),
    url("../fonts/CommutersSans-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "roboto";
  src: local("roboto"),
    url("../fonts/roboto.thin.ttf") format("truetype");
  font-weight: normal;
}


body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  background: url(../img/IMG_3756.JPG) no-repeat fixed;
  background-size: cover;
  background-position: center 40%;
  position: absolute;
  color: #342F7E;

  /* @media (max-width: @iphone-screen) {
    background-attachment: scroll;
    background-position: center 40%;
    background-size: cover;
  } */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  margin-left: auto;
  margin-right: auto;
  /* padding-top: 46px; */
  height: 100vh;
  width: 100%;
  margin: auto;
  display: flex;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  font-family: "Cormorant Garamond";
  color: #342F7E;
}

#home {
  padding-top: 100px;
  /* margin-top: 0%; */
  text-align: center;
  background-color: rgba(245, 245, 255, 0);
}

#home .container {
  height: 90%;
}

#home span {
  font-family: catchyMager;
  font-weight: 400;
  font-style: normal;
  font-size-adjust: auto;
  font-size: 10vw;
  width: 100%;
  letter-spacing: 0em;
  top: 50%;
  /* height: 20%;
  width: 50%; */
}

.h_ita {
  font-style: italic;
}

.h_nor {
  font-style: normal;
}


#home #info {
  /* display: contents; */
  justify-content: center;
  height: 40%;
}

#home #info #rsvp-btn-row {
  /* display: contents; */
  justify-content: center;
  height: 25%;
}

#home #spacer1 {
  height: 40%;
}

#home #spacer2 {
  height: 2%;
}

#home .row {
  height: 10%;
  align-items: center;
}

#home .btn {
  background-color: rgba(245, 245, 255, 0);
  border-color: #342F7E;
  height: 75px;
  width: 200px;
  font-size: 40px;
  border-width: 5%;
  border-radius: 0%;
  color: #342F7E;
}

#home .btn:hover {
  background-color: rgba(245, 245, 255, 0.3);
  color: #ffffff;
  border-color: #342F7E;
  height: 75px;
  width: 200px;
  font-size: 40px;
}

#about {
  background-color: #fffcf6;
  /* height: calc(100vh - 46px); */
  font-style: normal;
}



#about .container {
  align-items: center;
}

.hour {
  font-family: catchyMager;
}

.inti {
  font-family: "Pinyon Script", cursive;
}

#aboutTxt {
  margin-top: 20px;
}

#aboutTxt .aboutInfo {
  text-align: left;
  height: 50%;
}

.fit-picture {
  width: 95vw;
}

#contact {
  background-color: rgba(255, 255, 255, 0.3);
}



@media (min-width: 800px) {

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    width: 100vw;
    background: url(../img/IMG_3756.JPG) no-repeat fixed;
    background-size: cover;
    background-position: center 40%;
    position: absolute;
    color: #342F7E;
  }

  /* #home {
    padding-top: 250px;
  }

  #home .container {
    height: 70%;
  } */

  #home span {
    font-weight: 400;
    font-style: normal;
    font-size-adjust: auto;
    font-size: 5vw;
    width: 100%;
    letter-spacing: 0em;
    top: 50%;
  }

  #home #spacer1 {
    height: 40%;
  }

  #home #spacer2 {
    height: 5%;
  }

  #aboutTxt {
    margin-top: 100px;
  }

  .fit-picture {
    width: 40vw;
  }
}